import React from "react";
import _ from "lodash";

import { Layout } from "../components/index";
import { getPages, htmlToReact, safePrefix, Link } from "../utils";

export default class Blog extends React.Component {
  render() {
    let topLevelGuides = _.orderBy(
      _.filter(
        getPages(this.props.pageContext.pages, "/resources/guides"),
        guide => guide.frontmatter.top_level
      ),
      "frontmatter.order",
      "asc"
    );
    return (
      <Layout {...this.props}>
        <div className="outer">
          <div className="inner">
            <header className="post-header">
              <h1 className="post-title">Guides</h1>
            </header>
            <div className="post-content">
              {htmlToReact(_.get(this.props, "pageContext.html"))}
            </div>
            <div className="guides-list">
              {topLevelGuides.length === 0 && (
                <p style={{ margin: "0 auto" }}>
                  There are no guides yet. Try back in a while.
                </p>
              )}
              {_.map(topLevelGuides, (guide, guide_idx) => (
                <div key={guide_idx} className="guide-block">
                  <div className="guide-block-inside">
                    <Link to={safePrefix(_.get(guide, "url"))}>
                      <h4>{_.get(guide, "frontmatter.title")}</h4>
                      <p>{_.get(guide, "frontmatter.summary")}</p>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}
